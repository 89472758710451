export const PROTECTED_ROUTES = [
    '/',
    '/opportunities/[id]',
    '/opportunities/events/[id]',
    '/clients',
    '/clients/[id]',
    '/users',
    '/users/[id]',
    '/security',
    '/bonus',
    '/bonus/[id]',
    '/bonus-extension',
    '/tasks',
    '/remittances',
    '/solutions',
    '/dashboard',
    '/contract-issuance/[id]',
    '/onboarding-generate/[id]',
    '/distributors',
    '/distributors/[id]',
    '/campaign',
    '/campaign/[id]',
    '/secure-workplace',
    '/secure-workplace/devices',
]
